import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../style/LinkedInPost.scss";
import "../../style/InnerPage.scss";
import {
  ImagePlaceholder,
  LinkedInPostTextAnalysisLoader,
  LinkedInPostTextAnalysis,
  ImageHeatMap,
  Recommendations,
} from "../index";
import ReactReadMoreReadLess from "react-read-more-read-less";
import heatMapRefImage from "../../assets/images/heat_map_refernce.svg";

import { getRecommendations, getTextAnalysis, getHeatmap } from "../../utils";

export const LinkedInPost = () => {
  const { state } = useLocation();
  const [postData, setPostData] = useState({});
  const [textAnalysisResponse, setTextAnalysisResponse] = useState([]);
  const [heatMapImageResponse, setHeatMapImageResponse] = useState("");
  const [recommendationResponse, setRecommendationResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasTextResponse, setHasTextResponse] = useState(false);
  const [hasRecommendationResponse, setHasRecommendationResponse] =
    useState(false);
  const [hasHeatMapImageResponse, setHasHeatMapImageResponse] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const navigate = useNavigate();
  let imageHeatMapComponent;
  let processDataButton;
  let startOverButton;

  if (heatMapImageResponse) {
    imageHeatMapComponent = <ImageHeatMap result={heatMapImageResponse} />;
  }

  useEffect(() => {
    if (setPostData) {
      setPostData(state.postData);
    }
  }, [postData, state.postData]);

  const analyseTheContent = () => {
    setIsLoading(true);
    fetchTextAnalysiss();
    // analyseThePostImage();
    fetchHeatMap();
    fetchRecommendations();
  };

  /**
   * Validating the LinkedIn Post Image
   */

  const redirectToHome = () => {
    navigate(`/`);
  };

  // Fetch Recommendation
  const fetchRecommendations = async () => {
    setShowRecommendation(true);
    try {
      const responseData = await getRecommendations(
        {
          postContent: postData.postContent,
        },
        "path_to_excel",
        process.env.REACT_APP_POST_CHECKER_URI
      );
      setRecommendationResponse(responseData);
      setHasRecommendationResponse(true);
    } catch (error) {
      setHasRecommendationResponse(false);
    }
  };

  // Fetch Text Analysis
  const fetchTextAnalysiss = async () => {
    // setShowRecommendation(true);
    try {
      const responseData = await getTextAnalysis(
        {
          postContent: postData.postContent,
        },
        process.env.REACT_APP_LINKEDIN_APP_TEXT_ANALYSIS_URI
      );
      setTextAnalysisResponse(responseData);
      setHasTextResponse(true);
    } catch (error) {
      setHasTextResponse(false);
    }
  };

  // Fetch Heatmap
  const fetchHeatMap = async () => {
    try {
      const responseData = await getHeatmap(
        postData.postImageData,
        process.env.REACT_APP_LINKEDIN_APP_IMAGE_HEATMAP_URI
      );
      setHeatMapImageResponse(responseData);
      setHasHeatMapImageResponse(true);
    } catch (error) {
      setHasHeatMapImageResponse(false);
    }
  };

  // Process Button
  if (!isLoading && !hasTextResponse) {
    processDataButton = (
      <div className="linkedin-post__button-wrapper">
        <button onClick={analyseTheContent} className="linkedin-post__button">
          Process The Data
        </button>
      </div>
    );
  }

  // Start Over Button
  if (hasTextResponse && hasHeatMapImageResponse) {
    startOverButton = (
      <div className="linkedin-post__button-wrapper linkedin-post__button-wrapper--has-margin">
        <button className="linkedin-post__button" onClick={redirectToHome}>
          Start Over
        </button>
      </div>
    );
  }

  return (
    <div className="content-column inner-page">
      <div className="content">
        <div className="linkedin-post__wrapper">
          <div className="linkedin-post__image">
            <img src={postData.postImageUrl} alt="" />
          </div>
          <div className="linkedin-post__content">
            {isLoading && !hasHeatMapImageResponse ? (
              <ImagePlaceholder showBorder={true} />
            ) : (
              imageHeatMapComponent
            )}
          </div>
        </div>
        <div
          className={`linkedin-post__content-wrapper ${
            hasTextResponse ||
            (isLoading && "linkedin-post__content-wrapper--has-margin")
          }`}
        >
          <div className="linkedin-post__content-wrapper-col">
            <ReactReadMoreReadLess
              charLimit={200}
              readMoreText={"Read more ▼"}
              readLessText={"Read less ▲"}
              readMoreClassName="read-more-less--more"
              readLessClassName="read-more-less--less"
            >
              {postData.postContent
                ? postData.postContent
                    .replace(/[{}|\\]/g, "")
                    .replace(/hashtag/g, "")
                : ""}
            </ReactReadMoreReadLess>
            {processDataButton}
          </div>
          <div className="linkedin-post__content-wrapper-col">
            {hasHeatMapImageResponse ? (
              <img
                src={heatMapRefImage}
                className="image-heat-map__reference"
                alt="Feelim Refernce"
              />
            ) : (
              ""
            )}
            {isLoading && !hasTextResponse ? (
              <LinkedInPostTextAnalysisLoader />
            ) : (
              <LinkedInPostTextAnalysis result={textAnalysisResponse} />
            )}
          </div>
        </div>
        <>
          {showRecommendation && (
            <Recommendations
              result={recommendationResponse}
              response={hasRecommendationResponse}
            />
          )}
        </>
        <div>{startOverButton}</div>
      </div>
    </div>
  );
};
