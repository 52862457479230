import React, { useEffect, useState } from "react";
import { useLanguage } from "../../context/LanguageContext";

export const LanguageSwitcher = () => {
  const { languageCode, setLanguageCode } = useLanguage();

  return (
    <div>
      <ul className="lang_switcher">
        <li className="lang_switch">
          <button
            onClick={() => setLanguageCode("en")}
            className={`lang_switch__item ${
              languageCode === "en" ? "lang_switch__item--active" : ""
            }`}
          >
            EN
          </button>
        </li>
        <li className="lang_switch">
          <button
            onClick={() => setLanguageCode("fr")}
            className={`lang_switch__item ${
              languageCode === "fr" ? "lang_switch__item--active" : ""
            }`}
          >
            FR
          </button>
        </li>
        <li className="lang_switch">
          <button
            onClick={() => setLanguageCode("es")}
            className={`lang_switch__item ${
              languageCode === "es" ? "lang_switch__item--active" : ""
            }`}
          >
            ES
          </button>
        </li>
      </ul>
    </div>
  );
};
