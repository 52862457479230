import React from "react";
import "../../style/components/_login.scss";
import { useMsal } from "@azure/msal-react";

export const Logout = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect().catch((error) => {
      console.error("Login failed: ", error);
    });
  };

  return (
    <div className="logout">
      <button onClick={handleLogout} className="logout__button">
        Sign out
      </button>
    </div>
  );
};
