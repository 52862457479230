import axios from "axios";

// Utility function for making recommendations API call
export const getHeatmap = async (image_url, heatmap_only) => {
  // Prepare the request data
  const data = JSON.stringify({
    imageContent: image_url,
  });

  try {
    // Make the API request
    const response = await axios.post(
      process.env.REACT_APP_LINKEDIN_APP_IMAGE_ATTENTIONMAP_URI,
      {
        image_base64encoded: image_url,
        heatmap_only,
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching Heatmap:", error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};
