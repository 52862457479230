import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../App.scss";
import "../style/LinkedinPostAnalysis.scss";
import { Preloader } from "./Preloader";

const LinkedinPostAnalysis = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [emptyInputValue, setEmptyInputValue] = useState();
  const [postFetched, setPostFetched] = useState(false);

  const accessToken = state;

  /**
   * Validating the LinkedIn Post with the Access Token
   */
  const validateMyPost = useCallback(() => {
    setPostFetched(true);
    let data = JSON.stringify({
      token: accessToken.token,
      link: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_LINKEDIN_FUNCTION_URI,
      headers: {
        "x-functions-key": process.env.REACT_APP_LINKEDIN_FUNCTION_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        navigate(`/linkedin-post`, {
          state: {
            postData: response.data,
          },
        });
      })
      .then(() => {
        localStorage.removeItem("inputValue");
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken, inputValue, navigate]);

  useEffect(() => {
    const savedInputValue = localStorage.getItem("inputValue");
    if (savedInputValue) {
      setInputValue(savedInputValue);
    }

    if (accessToken && inputValue) {
      setTimeout(() => {
        validateMyPost();
      }, 250);
    }
  }, [accessToken, inputValue, validateMyPost]);

  /**
   * AUthenticating the user with LinkedIn
   */
  const linkedInAuth = () => {
    if (!inputValue) {
      setEmptyInputValue(true);
    } else {
      const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URI;
      const scope = process.env.REACT_APP_LINKEDIN_SCOPE;
      const state = process.env.REACT_APP_LINKEDIN_STATE;
      const linkedInUrl = `${process.env.REACT_APP_LINKEDIN_AUTH_URI}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
      window.location.href = linkedInUrl;
    }
  };

  /**
   *
   * storing the Post url to the State
   */
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue) {
      setEmptyInputValue(false);
    }
    setInputValue(newValue);
    localStorage.setItem("inputValue", newValue);
  };

  return (
    <div className="content-column">
      <div className="content-column__content">
        <p>
          Enter your LinkedIn post URL. Make sure it's from a company profile,
          and you have admin access. Then, click 'Login with LinkedIn,' sign in,
          and we'll retrieve your post for analysis.
        </p>
        <div className="input-container">
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder="Type or Paste the URL of your LinkedIn Post"
            className={`content-column__input-text ${
              emptyInputValue ? "error" : "valid"
            }`}
          />
          <button onClick={linkedInAuth} disabled={postFetched}>
            Login with LinkedIn
          </button>
        </div>
        <div
          className={`input-container__notification-label ${
            emptyInputValue ? "show" : "hide"
          }`}
        >
          <label className="error">Please add your post URL</label>
        </div>
        <Preloader status={postFetched} position="fixed" />
      </div>
    </div>
  );
};

export default LinkedinPostAnalysis;
