import React from "react";
import "../../../style/ImagePlaceholder.scss";

export const ParagraphLoader = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div className="content-block-loader__row" key={index}>
          <div className="text-placeholder"></div>
          <div className="text-placeholder"></div>
          <div className="text-placeholder"></div>
        </div>
      ))}
    </>
  );
};
