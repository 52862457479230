import React from "react";
import "../../../style/ImagePlaceholder.scss";

export const TextLoader = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="text-placeholder"></div>
      ))}
    </>
  );
};
