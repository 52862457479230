import React, { useEffect, useState } from "react";
import { useLanguage } from "../../context/LanguageContext";
import enIcon from "../../assets/images/en.png";
import frIcon from "../../assets/images/fr.png";
import esIcon from "../../assets/images/es.png";

export const LanguageSwitcher = () => {
  const { languageCode, setLanguageCode } = useLanguage();

  return (
    <ul className="lang_switcher">
      <li className="lang_switch">
        <button
          onClick={() => setLanguageCode("en")}
          className={`lang_switch__item ${
            languageCode === "en" ? "lang_switch__item--active" : ""
          }`}
        >
          <img src={enIcon} alt="English" title="English" />
        </button>
      </li>
      <li className="lang_switch">
        <button
          onClick={() => setLanguageCode("fr")}
          className={`lang_switch__item ${
            languageCode === "fr" ? "lang_switch__item--active" : ""
          }`}
        >
          <img src={frIcon} alt="French" title="French" />
        </button>
      </li>
      <li className="lang_switch">
        <button
          onClick={() => setLanguageCode("es")}
          className={`lang_switch__item ${
            languageCode === "es" ? "lang_switch__item--active" : ""
          }`}
        >
          <img src={esIcon} alt="Spanish" title="Spanish" />
        </button>
      </li>
    </ul>
  );
};
