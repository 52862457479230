import axios from "axios";

// Utility function for making Trends API call
export const getRelevantTrends = async (uri) => {
  const currentLanguage = localStorage.getItem("languageCode");
  // Set up the request configuration
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${uri}&languageCode=${currentLanguage}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    // Make the API request
    const response = await axios.request(config);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching relevant trends:", error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};
