import React, { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  LanguageSwitcher,
  LinkedInAuth,
  LinkedInPost,
  LinkedinPostAnalysis,
  LinkedInRedirect,
  Sidebar,
} from "./components";
import { LanguageProvider } from "./context/LanguageContext";

import {
  LoginForm,
  Home,
  PostAnalysis,
  IndustriesAndTrends,
  Trends,
  Registration,
  HomeWrapper,
} from "pages";
import { loginRequest } from "authConfig";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import "./App.scss";

const ProfileContent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [contentAreaHeight, setContentAreaHeight] = useState("100px");
  const [sidebarHeight, setSidebarHeight] = useState("100px");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sidebarPosition =
    windowWidth < 680 ? "80px" : isSidebarOpen ? "320px" : "80px";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      updateHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateHeights = () => {
    const footer = document.querySelector(".footer") as HTMLElement | null;
    const footerHeight = footer ? footer.offsetHeight : 0;

    if (window.innerWidth < 680) {
      // On mobile, set sidebar height to full viewport height
      setSidebarHeight("100vh");
    } else {
      // On larger screens, subtract footer height from viewport height
      setSidebarHeight(`${window.innerHeight - footerHeight}px`);
    }

    setContentAreaHeight(`${window.innerHeight - footerHeight - 52}px`);
  };

  useEffect(() => {
    updateHeights();
    window.addEventListener("resize", updateHeights);
    return () => window.removeEventListener("resize", updateHeights);
  }, []);

  // ToDo: Get token from instance and use the acquired token in header while making azure function request
  // const { instance, accounts } = useMsal();
  // function RequestProfileData() {
  //     // Silently acquires an access token which is then attached to a request for MS Graph data
  //     instance
  //         .acquireTokenSilent({
  //             ...loginRequest,
  //             account: accounts[0],
  //         })
  //          .then((response) => {
  //           //     callMsGraph(response.accessToken).then((response) => setGraphData(response));
  //             console.log("accessToken: " + response.tokenType)
  //          });
  // }

  return (
    <>
      <LanguageProvider>
        <div className="container-fluid">
          <div className="main-content">
            <Sidebar
              isOpen={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
              sidebarHeight={sidebarHeight}
            />

            <div
              className="content-area"
              style={{
                // marginLeft: sidebarPosition,
                transition: "all 0.3s ease",
                minHeight: contentAreaHeight,
              }}
            >
              <div
                className="toggle-wrapper"
                style={{
                  // marginLeft: isSidebarOpen ? "320px" : "80px",
                  transition: "all 0.3s ease",
                  visibility: windowWidth < 680 ? "hidden" : "visible",
                }}
              >
                {isSidebarOpen ? (
                  <MenuOpenIcon
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className="sidebar-nav__close"
                  />
                ) : (
                  <MenuIcon
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className="sidebar-nav__close"
                  />
                )}
              </div>
              <Router>
                <Routes>
                  <Route path="/login" element={<LoginForm />} />
                  <Route path="/sign-up" element={<Registration />} />
                  {/* <Route path="/" element={<Home />} /> */}
                  <Route
                    path="/linkedin-post-analysis"
                    element={<LinkedinPostAnalysis />}
                  />
                  <Route path="/post-analysis" element={<PostAnalysis />} />
                  <Route path="/trends" element={<Trends />} />
                  <Route
                    path="/industries-and-trends"
                    element={<IndustriesAndTrends />}
                  />
                  <Route path="/linkedin-auth" element={<LinkedInRedirect />} />
                  <Route path="/linkedin-callback" element={<LinkedInAuth />} />
                  <Route path="/linkedin-post" element={<LinkedInPost />} />
                </Routes>
              </Router>
            </div>
          </div>
          <footer className="footer">
            &copy; Feelim. All rights reserved.
          </footer>
        </div>
      </LanguageProvider>
    </>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        {/* <div className="home__wrapper">
              <div className="home__intro">
                <h4>Welcome to Feelim!</h4>
                <p>
                  Please login to explore all features.
                </p>
              </div>
            </div> */}
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <HomeWrapper>
      <MainContent />
    </HomeWrapper>
  );
}
