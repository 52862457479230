import React, { useRef, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "../../style/AccordionItem.scss";
import { FeelimDialog } from "components";
export const AccordionItem = ({
  ageRange,
  author,
  industries,
  keyword,
  post,
  trend,
  urlPost,
  isAccordionOpen,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPostData, setSelectedPostData] = useState();
  const handleModelOpen = () => {
    setIsOpen(true);
    setSelectedPostData({ text: post, url: urlPost });
  };

  const contentHeight = useRef();
  return (
    <div className="accordion__wrapper">
      <button
        className={`accordion__title ${isAccordionOpen ? "active" : ""}`}
        onClick={onClick}
      >
        {trend}
        <RiArrowDropDownLine
          className={`arrow ${isAccordionOpen ? "active" : ""}`}
        />
      </button>

      <div
        ref={contentHeight}
        className="accordion__content-container"
        style={
          isAccordionOpen
            ? { height: contentHeight.current.scrollHeight + 40 }
            : { height: "0px" }
        }
      >
        <div className="trends__content">
          <div className="trends__content-item trends__content-item--reverse">
            <div className="trends__items-row">
              <label className="trends__label">Industries</label>
            </div>
            <div className="trends__items-row">
              {industries.split(",").map((industry, index) => {
                return (
                  <label key={index} className="trends__items-label">
                    {industry}
                  </label>
                );
              })}
            </div>
          </div>
          <div className="trends__content-item trends__content-item--reverse">
            <div className="trends__items-row">
              <label className="trends__label">Keywords</label>
            </div>
            <div className="trends__items-row">
              {keyword.split(",").map((item, index) => {
                return (
                  <label key={index} className="trends__items-label">
                    {item}
                  </label>
                );
              })}
            </div>
          </div>

          <div className="trends__content-item">
            <div className="trends__items-row">
              <label className="trends__label">Author</label>
            </div>
            <div className="trends__items-row">
              <label className="trends__items-label">{author}</label>
            </div>
          </div>

          <div className="trends__content-item">
            <div className="trends__items-row">
              <label className="trends__label">Age Range</label>
            </div>
            <div className="trends__items-row">
              <label className="trends__items-label">{ageRange}</label>
            </div>
          </div>

          <div className="trends__content-item trends__content-item--reverse">
            <div className="trends__items-row">
              <label className="trends__label">Post</label>
            </div>
            <div className="trends__items-row">
              <label className="trends__items-label--normal">{post}</label>
            </div>
          </div>
          <div className="trends__content-item">
            <div className="trends__items-row">
              <label className="trends__label">URL Post</label>
            </div>
            <div className="trends__items-row">
              <button
                className="trends__items-link"
                rel="noreferrer"
                onClick={handleModelOpen}
              >
                Click here to vist the Post
              </button>
            </div>
          </div>
        </div>
      </div>
      <FeelimDialog
        data={selectedPostData}
        isDataFetching={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};
