import React from "react";
import "../../style/Recommendation.scss";
import { TextLoader } from "../index";

export const Recommendations = (props) => {
  const recommendationDetails = props.result;

  return (
    <div className="recommendation">
      <div className="recommendation__col">
        <div className="recommendation__items-col">
          <div className="recommendation__items-row">
            <label className="recommendation__label">AI Percentage</label>
          </div>
          <div className="recommendation__items-row">
            {recommendationDetails ? (
              <label className="recommendation__items-label">
                {recommendationDetails.aiPercentage}
              </label>
            ) : (
              <TextLoader count={1} />
            )}
          </div>
        </div>
        <div className="recommendation__items-col">
          <div className="recommendation__items-row">
            <label className="recommendation__label">Human Percentage</label>
          </div>
          <div className="recommendation__items-row">
            {recommendationDetails ? (
              <label className="recommendation__items-label">
                {recommendationDetails.humanPercentage}
              </label>
            ) : (
              <TextLoader count={1} />
            )}
          </div>
        </div>

        <div className="recommendation__items-col">
          <div className="recommendation__items-row">
            <label className="recommendation__label">Age Range</label>
          </div>
          <div className="recommendation__items-row">
            {recommendationDetails ? (
              <label className="recommendation__items-label">
                {recommendationDetails.ageRange}
              </label>
            ) : (
              <TextLoader count={1} />
            )}
          </div>
        </div>
        <div className="recommendation__items-col recommendation__items-col--reverse">
          <div className="recommendation__items-row">
            <label className="recommendation__label">Top 5 Keywords</label>
          </div>
          <div className="recommendation__items-row">
            {recommendationDetails && recommendationDetails.top_5_Keywords ? (
              <>
                {recommendationDetails.top_5_Keywords
                  .split(",")
                  .map((keyword, index) => {
                    return (
                      <label
                        key={index}
                        className="recommendation__items-label"
                      >
                        {keyword}
                      </label>
                    );
                  })}
              </>
            ) : (
              <TextLoader count={2} />
            )}
          </div>
        </div>
        <div className="recommendation__items-col recommendation__items-col--reverse">
          <div className="recommendation__items-row">
            <label className="recommendation__label">Top Industries</label>
          </div>
          <div className="recommendation__items-row">
            {recommendationDetails && recommendationDetails.topIndustries ? (
              <>
                {recommendationDetails.topIndustries
                  .split(",")
                  .map((industriy, index) => {
                    return (
                      <label
                        key={index}
                        className="recommendation__items-label"
                      >
                        {industriy}
                      </label>
                    );
                  })}
              </>
            ) : (
              <TextLoader count={1} />
            )}
          </div>
        </div>
      </div>
      <div className="recommendation__col">
        <div className="recommendation__items-col recommendation__items-col--no-border">
          <div
            className={`recommendation__items-list ${
              !recommendationDetails
                ? "recommendation__items-list--show-loader"
                : ""
            }`}
          >
            <label className="recommendation__items-list--title">
              Copy Recommendations
            </label>
            <div className="recommendation__items-list-item">
              {recommendationDetails ? (
                <label className="recommendation__items-list-label recommendation__items-label--text-left">
                  {recommendationDetails.copySuggestion_1}
                </label>
              ) : (
                <TextLoader count={1} />
              )}
            </div>
            <div className="recommendation__items-list-item">
              {recommendationDetails ? (
                <label className="recommendation__items-list-label recommendation__items-label--text-left">
                  {recommendationDetails.copySuggestion_2}
                </label>
              ) : (
                <TextLoader count={1} />
              )}
            </div>
            <div className="recommendation__items-list-item">
              {recommendationDetails ? (
                <label className="recommendation__items-list-label recommendation__items-label--text-left">
                  {recommendationDetails.copySuggestion_3}
                </label>
              ) : (
                <TextLoader count={1} />
              )}
            </div>
            <div className="recommendation__items-list-item">
              {recommendationDetails ? (
                <label className="recommendation__items-list-label recommendation__items-label--text-left">
                  {recommendationDetails.copySuggestion_4}
                </label>
              ) : (
                <TextLoader count={1} />
              )}
            </div>
            <div className="recommendation__items-list-item">
              {recommendationDetails ? (
                <label className="recommendation__items-list-label recommendation__items-label--text-left">
                  {recommendationDetails.copySuggestion_5}
                </label>
              ) : (
                <TextLoader count={1} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
