import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IPostDetails } from "@types";
import { ContentLoader } from "components";

interface ChildComponentProps {
  data: IPostDetails | undefined;
  isDataFetching: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeelimDialog = ({
  data,
  isDataFetching,
  isOpen,
  setIsOpen,
}: ChildComponentProps) => {
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    // Prevent closing on outside click or escape key
    if (reason === "backdropClick") return;
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Post Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setIsOpen(false)}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {isDataFetching && (
          <div>
            <ContentLoader />
          </div>
        )}
        {!isDataFetching && data && (
          <div className="modal__details">
            <div className="modal__details">
              <img
                src={data.url}
                alt="Feelim Post Details"
                style={{ maxWidth: "100%" }}
                className="modal__image"
              />
              <p className="modal__content">{data.text}</p>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
