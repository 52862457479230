import React from "react";
import { Bubble } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  TooltipItem,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ContentLoader from "components/Loaders/contentLoader";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  zoomPlugin
);

interface BubbleDataPoint {
  x: number;
  y: number;
  r: number;
  id: number;
}

interface ZoomableBubbleChartProps {
  data: BubbleDataPoint[];
  onPostSelect: (postId: number) => void;
  isChartLoading: boolean;
}

// Helper function to scale bubble size
const scaleBubbleSize = (
  data: BubbleDataPoint[],
  maxBubbleRadius: number = 50
) => {
  const maxR = Math.max(...data.map((point) => point.r), 1);
  return data.map((point) => ({
    ...point,
    r: (point.r / maxR) * maxBubbleRadius,
  }));
};

export const ZoomableBubbleChart: React.FC<ZoomableBubbleChartProps> = (
  props
) => {
  const { data, onPostSelect, isChartLoading } = props;
  const scaledData = scaleBubbleSize(data); // Scale bubble sizes
  // Set a maximum limit for the chart axes to avoid excessive scaling
  const MAX_X_AXIS = 50; // Set a reasonable max value for x-axis (shareCount)
  const MAX_Y_AXIS = 400; // Set a reasonable max value for y-axis (reactionCount)

  const COLORS = [
    "#54bebe",
    "#76c8c8",
    "#98d1d1",
    "#badbdb",
    "#dedad2",
    "#e4bcad",
    "#df979e",
    "#d7658b",
    "#c80064",
  ];

  const chartData: ChartData<"bubble"> = {
    datasets: [
      {
        label: "Posts Engagement",
        data: scaledData,
        backgroundColor: COLORS,
        borderColor: "#FFFFFF57",
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"bubble"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        beginAtZero: true,
        min: 0,
        max: MAX_X_AXIS, // Set max x-axis value
        title: {
          display: true,
          text: "Share Count",
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: MAX_Y_AXIS, // Set max y-axis value
        title: {
          display: true,
          text: "Reaction Count",
        },
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: "xy",
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Tooltip background color
        titleFont: {
          family: "Arial",
          size: 14,
          weight: "bold",
          style: "italic",
        },
        bodyFont: {
          family: "Arial",
          size: 12,
        },
        padding: 10,
        callbacks: {
          title: () => "Post Details",
          label: (tooltipItem: TooltipItem<"bubble">) => {
            const point = tooltipItem.raw as BubbleDataPoint;
            return [
              `Share Count: ${point.x}`,
              `Reaction Count: ${point.y}`,
              //   `Post Id: ${point.id}`,
            ];
          },
        },
      },
    },
    onClick: (event, elements) => {
      // Ensure an element was clicked
      if (elements.length > 0) {
        const chartElement = elements[0];
        const datasetIndex = chartElement.datasetIndex;
        const index = chartElement.index;

        // Retrieve the selected data point
        const selectedData = data[index];

        // Trigger the onPostSelect handler with selected data
        onPostSelect(selectedData.id);
      }
    },
  };

  return (
    <>
      {!isChartLoading && <Bubble data={chartData} options={options} />}
      {isChartLoading && (
        <>
          <br />
          <br />
          <br />
          <ContentLoader />
        </>
      )}
    </>
  );
};
