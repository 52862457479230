import React, { createContext, useContext, useEffect, useState } from "react";

const LanguageContext = createContext({
  languageCode: "en",
  setLanguageCode: (lang: string) => {},
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [languageCode, setLanguageCode] = useState(
    localStorage.getItem("languageCode") || "en"
  );

  const updateLanguage = (lang: string) => {
    localStorage.setItem("languageCode", lang);
    setLanguageCode(lang);
  };

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "languageCode" && event.newValue) {
        setLanguageCode(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <LanguageContext.Provider
      value={{ languageCode, setLanguageCode: updateLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
