import React from "react";
import "../../../style/LinkedInPostTextAnalysisLoader.scss";

export const LinkedInPostTextAnalysisLoader = () => {
  return (
    <div className="text-analysis__wrapper loader-wrapper">
      <div className="text-analysis__content loader-wrapper__content">
        <div className="text-analysis__content-column loader-title">
          <div className="loader-row">
            <div className="text-placeholder"></div>
          </div>
          <div className="loader-row">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="text-analysis__content-column loader-title">
          <div className="loader-row">
            <div className="text-placeholder"></div>
          </div>
          <div className="loader-row">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="text-analysis__content-column loader-title">
          <div className="loader-row">
            <div className="text-placeholder"></div>
          </div>
          <div className="loader-row">
            <div className="text-placeholder"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
