import React from "react";
import "../../style/ImagePlaceholder.scss";
import "../../style/components/_content-loader.scss";
import ParagraphLoader from "./ParagraphLoader";

const ContentLoader = () => {
  return (
    <div className="content-loader">
      <div className="image-loader"></div>
      <div className="content-block-loader">
        <ParagraphLoader count={1} />
        <ParagraphLoader count={1} />
      </div>
    </div>
  );
};

export default ContentLoader;
