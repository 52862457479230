import React from "react";
import "../../style/Trends.scss";
import { Accordion, TextLoader } from "../index";

export const TrendsDetails = (props) => {
  const { trendKeywords, isRelevantTrendsLoading } = props;
  const trends = props.result.topTrends;
  return (
    <div className="trends__wrapper">
      {trendKeywords && (
        <div className="trends__keywords-container">
          Trends for <div className="trends__keywords">{trendKeywords}</div>
        </div>
      )}
      <div className="trends__content-wrapper">
        {isRelevantTrendsLoading && <TextLoader count={1} />}
        {!isRelevantTrendsLoading && <Accordion data={trends} />}
      </div>
    </div>
  );
};
