import React from "react";
import "../style/ImageHeatMap.scss";

export const ImageHeatMap = (props) => {
  const heatMapImage = props.result;
  return (
    <div className="image-heat-map__wrapper">
      <div className="image-heat-map__image">
        <img src={heatMapImage} alt="Feelim Heatmap" />
      </div>
    </div>
  );
};
