import React from "react";
import "../../style/TextAnalysis.scss";

export const LinkedInPostTextAnalysis = (props) => {
  const textAlalysisResult = props.result;
  return (
    <div className="text-analysis__wrapper">
      {textAlalysisResult.qualityRating !== undefined && (
        <div className="text-analysis__content">
          <div className="text-analysis__content-column">
            <div className="text-analysis__content-row">
              <label className="text-analysis__content-title">
                Quality Rating
              </label>
            </div>
            <div className="text-analysis__content-row">
              <label className="text-analysis__content-row--highlight">
                {textAlalysisResult.qualityRating}/10
              </label>
            </div>
          </div>
          <div className="text-analysis__content-column">
            <div className="text-analysis__content-row">
              <label className="text-analysis__content-title">
                Clarity Rating
              </label>
            </div>
            <div className="text-analysis__content-row ">
              <label className="text-analysis__content-row--highlight">
                {textAlalysisResult.clarityRating}/10
              </label>
            </div>
          </div>
          <div className="text-analysis__content-column text-analysis__content-column--reverse">
            <div className="text-analysis__content-row">
              <label className="text-analysis__content-title">
                Keyword List
              </label>
            </div>
            <div className="text-analysis__content-row text-analysis__legend-row">
              {textAlalysisResult.keywordList
                .join(", ")
                .split(", ")
                .map((keyword, index) => {
                  return (
                    <label
                      key={index}
                      className="text-analysis__content-row--highlight"
                    >
                      {keyword}
                    </label>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
