import React, { useState } from "react";
import { AccordionItem } from "../index";
import "../../style/Accordion.scss";
export const Accordion = (props) => {
  const data = props.data;
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="accordion__container">
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          isAccordionOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
          ageRange={item.ageRange}
          author={item.author}
          industries={item.industries}
          keyword={item.keyword}
          post={item.post}
          trend={item.trend}
          urlPost={item.urlPost}
        />
      ))}
    </div>
  );
};
