import React, { useState } from "react";
import "../../style/components/_login.scss";
import axios from "axios";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "xxxxxxxxx",
    // authority: "https://feelim.b2clogin.com/feelim.onmicrosoft.com/feelimsu",
    authority: "https://feelim.b2clogin.com/feelim.onmicrosoft.com",
    knownAuthorities: ["feelim.b2clogin.com"],
    // redirectUri: "https://app.feelim.io", // Redirect URI after authentication
    redirectUri: "http://localhost:3000/auth-redirect", // Redirect URI after authentication
    postLogoutRedirectUri: "https://app.feelim.io", // Redirect URI after logout
  },
  cache: {
    cacheLocation: "localStorage", // Or "sessionStorage"
    storeAuthStateInCookie: false, // For IE11/Edge compatibility
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

export const Registration = () => {
  // State for email and password
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSignUp = async () => {
    console.log("handleSignUp");

    console.log("msal Instance", msalInstance);

    // await msalInstance.initialize();

    msalInstance
      .loginRedirect({
        authority:
          "https://feelim.b2clogin.com/feelim.onmicrosoft.com/B2C_1_feelimsu",
        scopes: ["openid", "profile"],
      })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  // Handle login submission
  const handleRegistration = async (e: React.FormEvent) => {
    e.preventDefault();
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    await msalInstance
      .initialize()
      .then((tokenResponse) => {
        console.log("tokenResponse", tokenResponse);

        // Check if the tokenResponse is null
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
        // If the tokenResponse === null, you are not coming back from an auth redirect.
      })
      .catch((error) => {
        console.log("error", error);
        // handle error, either in the library or coming back from the server
      });

    // try {
    //   // Make an Axios POST request
    //   const response = await axios.post(
    //     "https://feelim.b2clogin.com/feelim.onmicrosoft.com/",
    //     {
    //       email,
    //       password,
    //     }
    //   );

    //   // Assuming the API responds with { success: true, token: "your-jwt-token" }
    //   if (response.data.success && response.data.token) {
    //     // Store token in localStorage
    //     localStorage.setItem("authToken", response.data.token);
    //     alert("Login successful! Token stored in localStorage.");
    //   } else {
    //     alert("Login failed! Please check your credentials.");
    //   }
    // } catch (error) {
    //   console.error("Login error:", error);
    //   alert("An error occurred during login.");
    // }
  };

  return (
    <div className="login-container">
      <div className="login-intro"></div>
      <div className="login-form">
        <h2>Signup</h2>
        <form onSubmit={handleRegistration} className="login-form__form">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Signup
          </button>
        </form>
        <button onClick={handleSignUp}>Sign Up</button>

        <div className="divider">Or Login With</div>
        <div className="login-form__other-options">
          <LinkedInIcon />
          <FacebookIcon />
        </div>
      </div>
    </div>
  );
};
