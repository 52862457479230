import React, { useEffect, useState } from "react";
import { IPostsData, IPostDetails } from "@types";
import {
  getIndustries,
  getPosts,
  getPostDetails,
  getRelevantTrends,
} from "../../utils";

// Dropdown
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Tab
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import {
  FeelimDialog,
  ZoomableBubbleChart,
  TextLoader,
  TrendsDetails,
} from "components";
import { useLanguage } from "../../context/LanguageContext";

interface BubbleDataPoint {
  x: number; // shareCount as x-axis value
  y: number; // reactionCount as y-axis value
  r: number; // calculated size based on both shareCount and reactionCount
  id: number;
}

export const IndustriesAndTrends = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPostDetailsLoading, setIsPostDetailsLoading] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [value, setValue] = useState("topContent");
  const [isOpen, setIsOpen] = useState(false);
  const [topContentIndustry, setTopContentIndustry] = useState("");
  const [trendingIndustry, setTrendingIndustry] = useState("");
  const [topContentDropdown, setTopContentDropdown] = useState([]);
  const [trendingDropdown, setTrendingDropdown] = useState([]);
  const [hasIndustriesResponse, setHasIndustriesResponse] = useState(false);
  const [postsData, setPostsData] = useState<IPostsData[]>([]);
  const [chartsData, setChartsData] = useState<BubbleDataPoint[]>([]);
  const [selectedPostData, setSelectedPostData] = useState<IPostDetails>();
  const [relevantTrendsResponse, setRelevantTrendsResponse] = useState();
  const [hasRelevantTrendsResponse, setHasRelevantTrendsResponse] =
    useState<boolean>(false);
  const [showRelevantTrends, setShowRelevantTrends] = useState<boolean>(false);
  const [isRelevantTrendsLoading, setIsRelevantTrendsLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(false);
  const [cahrtMin, setChartMin] = useState<number>(0);
  const [cahrtMax, setChartMax] = useState<number>(0);
  const { languageCode } = useLanguage();

  const handleSelectedPost = (postId: number) => {
    fetchPostDetails(postId);
    handleModelOpen();
  };

  const handleModelOpen = () => setIsOpen(true);

  // Fetch Industries
  const fetchIndustries = async () => {
    try {
      const responseData = await getIndustries(
        process.env.REACT_APP_GET_INDUSTRIES_URI
      );

      setTopContentDropdown(responseData);
      setTrendingDropdown(responseData);
      setHasIndustriesResponse(true);
    } catch (error) {
      setHasIndustriesResponse(false);
      return error;
    }
  };

  // Fetch Relevant Trends
  const fetchRelevantTrends = async (industry?: string) => {
    let relevantTrendsUrl;
    if (industry) {
      relevantTrendsUrl = `${process.env.REACT_APP_GET_RELEVANT_TRENDS_URI}&industryName=${industry}`;
    } else {
      relevantTrendsUrl = `${process.env.REACT_APP_GET_RELEVANT_TRENDS_URI}`;
    }

    setShowRelevantTrends(true);
    setIsRelevantTrendsLoading(true);
    try {
      const responseData = await getRelevantTrends(relevantTrendsUrl);
      if (responseData) {
        setRelevantTrendsResponse(responseData);
        setHasRelevantTrendsResponse(true);
        setIsRelevantTrendsLoading(false);
      }
    } catch (error) {
      setHasRelevantTrendsResponse(false);
      return error;
    }
  };

  const transformData = (data: IPostsData[]): BubbleDataPoint[] => {
    return data.map((item) => {
      const shareCount = parseInt(item.shareCount, 10) || 0;
      const reactionCount = parseInt(item.reactionCount, 10) || 0;

      // Adjust the size of the bubble using a scaling factor (tweak as needed)
      const sizeFactor = 2; // Adjust this to control bubble size
      const radius = Math.sqrt(shareCount * reactionCount) * sizeFactor;

      return {
        x: shareCount,
        y: reactionCount,
        r: radius,
        id: item.postId,
      };
    });
  };

  // Fetch Posts
  const fetchPosts = async (industry?: string) => {
    let url;
    if (industry) {
      url = `${process.env.REACT_APP_GET_POSTS_URI}&industryName=${industry}`;
    } else {
      url = `${process.env.REACT_APP_GET_POSTS_URI}`;
    }

    try {
      const responseData = await getPosts(url);
      if (responseData) {
        setIsChartLoading(false);
        setPostsData(responseData);

        const transformedData = transformData(responseData);
        setChartsData(transformedData);
        const { minY, maxY } = transformedData.reduce(
          (acc, curr) => {
            return {
              minY: Math.min(acc.minY, curr.y),
              maxY: Math.max(acc.maxY, curr.y),
            };
          },
          { minY: Infinity, maxY: -Infinity }
        );
        setChartMin(minY);
        setChartMax(maxY);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Fetch Post Details
  const fetchPostDetails = async (postId: number) => {
    try {
      setIsPostDetailsLoading(true);
      const responseData = await getPostDetails(
        `${process.env.REACT_APP_GET_POST_DETAILS_URI}&postId=${postId}`
      );
      if (responseData) {
        setIsPostDetailsLoading(false);
        setSelectedPostData(responseData);
      }
    } catch (error) {
      setIsPostDetailsLoading(true);
      return error;
    }
  };

  const loadInit = () => {
    // console.log("Initializing...");
    setShowTabs(true);
    setIsLoading(true);
    fetchIndustries();
    fetchPosts();
    fetchRelevantTrends();
  };

  // Handle Tabs change
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // Handle Top Content Dropdown change
  const handleTopContentChange = (event: SelectChangeEvent) => {
    setIsChartLoading(true);
    fetchPosts(event.target.value);
    setTopContentIndustry(event.target.value as string);
  };

  // Handle Trends Dropdown change
  const handleTrendsChange = (event: SelectChangeEvent) => {
    setIsRelevantTrendsLoading(true);
    fetchRelevantTrends(event.target.value);
    setTrendingIndustry(event.target.value as string);
  };

  useEffect(() => {
    loadInit();
    // console.log(`Language changed to: ${languageCode}`);
  }, [languageCode]);

  return (
    <div className="post-analysis__wrapper">
      <div className="post-analysis__content">
        {showTabs && (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="Feelim Trends Analysis"
                variant="fullWidth"
              >
                <Tab label="Top Content" value="topContent" />
                <Tab label="Trending" value="trendingContent" />
              </TabList>
            </Box>

            <TabPanel value="topContent" sx={{ padding: "16px 8px" }}>
              <div>
                <p>
                  Below, we present some of the most relevant posts today. You
                  can filter by keyword, or scroll down and search by keyword to
                  find the top 10 trends on Linkedin
                </p>
                {isLoading && !hasIndustriesResponse && (
                  <TextLoader count={1} />
                )}
                {hasIndustriesResponse && (
                  <FormControl fullWidth>
                    <InputLabel id="trends-select-label">Trends</InputLabel>
                    <Select
                      labelId="trends-select-label"
                      id="demo-simple-select"
                      value={topContentIndustry}
                      label="Trends"
                      onChange={handleTopContentChange}
                    >
                      {topContentDropdown &&
                        topContentDropdown.length &&
                        topContentDropdown.map(
                          (industry: string, index: number) => (
                            <MenuItem key={index} value={industry}>
                              {industry}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                )}
                {postsData.length > 0 && (
                  <>
                    <div style={{ width: "100%", height: "500px" }}>
                      <ZoomableBubbleChart
                        data={chartsData}
                        onPostSelect={handleSelectedPost}
                        isChartLoading={isChartLoading}
                        chartMin={cahrtMin}
                        chartMax={cahrtMax}
                      />
                    </div>
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel value="trendingContent" sx={{ padding: "16px 8px" }}>
              <div>
                <p>
                  Below, we present some of the most relevant posts today. You
                  can filter by keyword, or scroll down and search by keyword to
                  find the top 10 trends on Linkedin
                </p>

                {isLoading && !hasIndustriesResponse && (
                  <TextLoader count={1} />
                )}
                {hasIndustriesResponse && (
                  <FormControl fullWidth>
                    <InputLabel id="trends-select-label">Trends</InputLabel>
                    <Select
                      labelId="trends-select-label"
                      id="demo-simple-select"
                      value={trendingIndustry}
                      label="Trends"
                      onChange={handleTrendsChange}
                    >
                      {trendingDropdown &&
                        trendingDropdown.length &&
                        trendingDropdown.map(
                          (industry: string, index: number) => (
                            <MenuItem key={index} value={industry}>
                              {industry}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                )}

                {isRelevantTrendsLoading && !hasRelevantTrendsResponse && (
                  <TextLoader count={1} />
                )}

                {hasRelevantTrendsResponse && showRelevantTrends && (
                  <TrendsDetails
                    result={relevantTrendsResponse}
                    trendKeywords={trendingIndustry}
                    isRelevantTrendsLoading={isRelevantTrendsLoading}
                  />
                )}
              </div>
            </TabPanel>
          </TabContext>
        )}
        <FeelimDialog
          data={selectedPostData}
          isDataFetching={isPostDetailsLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  );
};
