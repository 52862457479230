import React, { useEffect, useState, useRef } from "react";
import "../../style/PostAnalysis.scss";
import { getRecommendations, getTextAnalysis, getHeatmap } from "../../utils";
import {
  LinkedInPostTextAnalysisLoader,
  LinkedInPostTextAnalysis,
  Recommendations,
} from "../../components";
import heatMapRefImage from "../../assets/images/heat_map_refernce.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactReadMoreReadLess from "react-read-more-read-less";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { TextCount } from "../../components";
import { BeforeAfter } from "../../components";
import { Mosaic } from "react-loading-indicators";

export const PostAnalysis = () => {
  const minInputString = 100;
  const maxInputString = 3000;
  const [image, setImage] = useState("");
  const [base64String, setBase64String] = useState("");
  const [textValue, setTextValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasDataResponse, setHasDataResponse] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableClear, setDisableClear] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showImageLoader, setShowImageLoader] = useState(false);
  const [showTextLoader, setShowTextLoader] = useState(false);

  const fileInputRef = useRef(null); // Create a ref for the file input
  const [recommendationResponse, setRecommendationResponse] = useState(null);
  const [hasRecommendationResponse, setHasRecommendationResponse] =
    useState(false);

  // Text Analysis
  const [textAnalysisResponse, setTextAnalysisResponse] = useState([]);
  const [hasTextResponse, setHasTextResponse] = useState(false);

  // Image Heatmap
  const [heatMapImageResponse, setHeatMapImageResponse] = useState("");
  const [hasHeatMapImageResponse, setHasHeatMapImageResponse] = useState(false);

  // Fetch Recommendation
  const fetchRecommendations = async () => {
    try {
      const responseData = await getRecommendations(
        {
          postContent: textValue,
        },
        "path_to_excel",
        process.env.REACT_APP_POST_CHECKER_URI
      );
      setRecommendationResponse(responseData);
      setHasRecommendationResponse(true);
      setIsLoading(false);
    } catch (error) {
      setHasRecommendationResponse(false);
    }
  };

  // Fetch Text Analysis
  const fetchTextAnalysiss = async () => {
    try {
      const responseData = await getTextAnalysis(
        {
          postContent: textValue,
        },
        process.env.REACT_APP_LINKEDIN_APP_TEXT_ANALYSIS_URI
      );
      setTextAnalysisResponse(responseData);
      setHasTextResponse(true);
      setIsLoading(false);
    } catch (error) {
      setHasTextResponse(false);
    }
  };

  // Fetch Heatmap
  const fetchHeatMap = async () => {
    try {
      const responseData = await getHeatmap(
        base64String,
        process.env.REACT_APP_LINKEDIN_APP_IMAGE_HEATMAP_URI
      );
      setHeatMapImageResponse(responseData);
      setHasHeatMapImageResponse(true);
      setIsLoading(false);
    } catch (error) {
      setHasHeatMapImageResponse(false);
    }
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file); // Keep track of the selected file
      const reader = new FileReader();
      // Read the file as an ArrayBuffer
      reader.readAsDataURL(file);

      reader.onload = () => {
        const image = reader.result;
        // Set the uploaded image
        setImage(image);
        // Get the Base64 string
        const base64String = image.split(",")[1]; // Split to remove the 'data:image/...;base64,' part
        setBase64String(base64String);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const processText = () => {
    if (textValue.length > 0) {
      setShowTextLoader(true);
      fetchRecommendations();
      fetchTextAnalysiss();
    }
  };
  const processImage = () => {
    if (base64String.length > 0) {
      setShowImageLoader(true);
      fetchHeatMap();
    }
  };

  // Call the function
  const processTextAndImage = () => {
    setIsLoading(true);
    // Process the image with heatmap
    processImage();
    // Process the text with TextAnalysis and checker
    processText();
  };

  const clearForm = () => {
    setTextValue("");
    setSelectedFile(null);
    setImage("");
    setBase64String("");
    setDisableSubmit(true);
    setDisableClear(true);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const redirectToHome = () => {
    setIsLoading(false);
    setHasDataResponse(false);
    setHasRecommendationResponse(false);
    setHasTextResponse(false);
    setIsLoading(false);
    setHasHeatMapImageResponse(false);
    setIsLoading(false);
    setShowImageLoader(false);
    setShowTextLoader(false);
    setTextAnalysisResponse([]);
    setRecommendationResponse(null);
    setHeatMapImageResponse(null);
    setImage("");
    setBase64String("");
    setTextValue("");
  };

  useEffect(() => {
    if (
      hasHeatMapImageResponse ||
      hasRecommendationResponse ||
      hasTextResponse
    ) {
      setHasDataResponse(true);
    }

    if (
      selectedFile &&
      textValue.length >= minInputString &&
      textValue.length <= maxInputString
    ) {
      setDisableSubmit(false); // Enable the button if conditions are met
    } else {
      setDisableSubmit(true); // Disable otherwise
    }

    if (textValue.length > 0 || selectedFile) {
      setDisableClear(false);
    }
  }, [
    textValue,
    selectedFile,
    isLoading,
    hasDataResponse,
    hasHeatMapImageResponse,
    hasRecommendationResponse,
    hasTextResponse,
  ]);

  return (
    <div className="post-analysis__wrapper">
      <div className="post-analysis__content">
        {!isLoading && !hasDataResponse && (
          <>
            <p>
              Now, let’s validate your content before you share it on LinkedIn.
              Select your post image and enter your text (make sure it's between
              <span className="bold"> {minInputString} </span>
              and <span className="bold"> {maxInputString} </span> characters
              for a better analysis). Click 'Continue,' and wait a few seconds
              while Feelim provides your results.
            </p>
            <div className="post-analysis__image-uploader Neon Neon-theme-dragdropbox">
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                onChange={handleFileChange}
                ref={fileInputRef} // Attach the ref to the file input
              />
            </div>
            <div className="post-analysis__content-area">
              <textarea
                onChange={handleTextChange}
                // disabled={disableInput}
                value={textValue}
              />
              <TextCount
                current={textValue.length}
                max={maxInputString}
                min={minInputString}
                limit={maxInputString}
              />
            </div>
            <div className="post-analysis__action-area">
              <button
                className="post-analysis__clear-button"
                onClick={clearForm}
                disabled={disableClear}
              >
                Clear
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  className="app-icon-space"
                />
              </button>
              <button
                className="post-analysis__button"
                disabled={disableSubmit}
                onClick={processTextAndImage}
              >
                Continue
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  className="app-icon-space"
                />
              </button>
            </div>
          </>
        )}
        {/* <>
          <div className="post-analysis__preloader-wrapper">
            <div className="post-analysis__preloader-container">
              <Mosaic color="#28a745" size="medium" text="" textColor="" />
            </div>
          </div>

          <BeforeAfter
            beforeImage={image}
            afterImage={image}
            className="before-after-image--hide-slider"
          />
        </> */}

        {showImageLoader && (
          <>
            <div className="before-after-image">
              {!heatMapImageResponse ? (
                <>
                  <div
                    className="before-after-image--hide-slider"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      width: "fit-content",
                      cursor: "resize",
                      userSelect: "none",
                    }}
                  >
                    <div className="post-analysis__preloader-wrapper">
                      <div className="post-analysis__preloader-container">
                        <Mosaic
                          color="#28a745"
                          size="medium"
                          text=""
                          textColor=""
                        />
                      </div>
                    </div>
                    <div
                      className="before"
                      style={{
                        position: "absolute",
                        overflow: "hidden",
                        width: "50%",
                        height: "100%",
                        top: 0,
                        left: 0,
                        borderRight: "2px solid rgb(238, 238, 238)",
                      }}
                    >
                      <img
                        src={image}
                        alt="before"
                        style={{ height: "100%" }}
                      />
                    </div>
                    <div className="after">
                      <img
                        src={image}
                        alt="after"
                        style={{ maxWidth: "100%", display: "block" }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <BeforeAfter
                  beforeImage={image}
                  afterImage={heatMapImageResponse}
                />
              )}
              <img
                src={heatMapRefImage}
                className="image-heat-map__reference"
                alt="Feelim Heatmap Reference"
              />
            </div>
            <div className="linkedin-post__wrapper">
              <div className="linkedin-post__full-column">
                <p className="linkedin-post__text-data hide-in-mobile">
                  <ReactReadMoreReadLess
                    charLimit={200}
                    readMoreText={"Read more ▼"}
                    readLessText={"Read less ▲"}
                    readMoreClassName="read-more-less--more"
                    readLessClassName="read-more-less--less"
                  >
                    {textValue}
                  </ReactReadMoreReadLess>
                </p>
              </div>
            </div>
            <p className="linkedin-post__text-data show-in-mobile">
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                {textValue}
              </ReactReadMoreReadLess>
            </p>
          </>
        )}

        {showTextLoader && !hasTextResponse ? (
          <LinkedInPostTextAnalysisLoader />
        ) : (
          <LinkedInPostTextAnalysis result={textAnalysisResponse} />
        )}

        {showTextLoader && (
          <Recommendations
            result={recommendationResponse}
            response={hasRecommendationResponse}
          />
        )}
        {hasDataResponse && (
          <div className="linkedin-post__button-wrapper linkedin-post__button-wrapper--align-right linkedin-post__button-wrapper--has-margin">
            <button className="linkedin-post__button" onClick={redirectToHome}>
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="app-icon-space"
              />
              Start Over
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
