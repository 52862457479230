import React from "react";
import "../../style/ImageAnalysis.scss";

export const LinkedInPostImageAnalysis = (props) => {
  const imageAlalysisResult = props.result;
  return (
    <div className="imageAnalysisWrapper">
      <div className="imageAnalysisContent">
        {/*<div className="imageAnalysisContentColumn imageAnalysisContentColumn__Title">
          <div className="imageAnalysisContentRow">Name</div>
          <div className="imageAnalysisContentRow">Confidence</div>
           <div className="imageAnalysisContentRow">Hint</div>
        </div> */}

        {imageAlalysisResult.tags.map((imageData) => {
          return (
            <div className="imageAnalysisContentColumn" key={imageData.name}>
              <div className="imageAnalysisContentRow">{imageData.name}</div>
              <div className="imageAnalysisContentRow">
                {imageData.confidence.toFixed(2)}
              </div>
              {/* <div className="imageAnalysisContentRow">{imageData.hint}</div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};
