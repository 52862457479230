import React from "react";
import "../../../style/ImagePlaceholder.scss";

export const ImagePlaceholder = (props) => {
  const height = props.height;
  const theme = props.theme;
  const showBorder = props.showBorder;
  return (
    <div
      style={{ height: height + "px" }}
      className={
        showBorder ? "placeholder-wrapper" : "placeholder-wrapper--no-border"
      }
    >
      <div className={theme ? theme : "image-placeholder"}></div>
    </div>
  );
};
