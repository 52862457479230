import React from "react";
import "../../style/components/TextCount.scss";

export const TextCount = (props) => {
  const data = props;
  return (
    <div className="text-count__wrapper">
      <div
        className={`text-count__content ${
          data.current < data.min || data.current >= data.limit
            ? "text-count__content text-count__content--error"
            : "text-count__content"
        }`}
      >
        {data.current}/{data.max}
      </div>
    </div>
  );
};
