import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-white.png";
import logoSmall from "../../assets/images/logo-white-small.png";
import { LanguageSwitcher } from "../index";
import { Logout } from "pages";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  sidebarHeight: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onClose,
  sidebarHeight,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sidebarWidth = windowWidth < 680 ? "80px" : isOpen ? "320px" : "80px";

  return (
    <div
      className={`sidebar sidebar-nav__wrapper ${isOpen ? "open" : "closed"}`}
      style={{
        // position: "fixed",
        top: 0,
        left: 0,
        //left: isOpen ? 0 : "-320px",
        width: sidebarWidth,
        // minHeight: sidebarHeight,
        boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
        transition: "all 0.3s ease",
      }}
    >
      <div
        className={`sidebar-nav__logo-column ${
          windowWidth < 680 ? "closed" : isOpen ? "open" : "closed"
        }`}
      >
        <a href="/">
          <img src={logo} alt="Logo" className="logo" />
        </a>
      </div>
      {/* <MenuIcon onClick={onClose} className="sidebar-nav__close" /> */}

      <nav className="sidebar-nav">
        <ul className="sidebar-nav__list">
          <li>
            <a className="sidebar-nav__list-link " href="/post-analysis">
              <span className="sidebar-nav__icon sidebar-nav__icon--document"></span>
              {isOpen && (
                <span className="sidebar-nav__nav-item">
                  Check Before Publishing
                </span>
              )}
            </a>
          </li>
          <li>
            <a
              className="sidebar-nav__list-link "
              href="/linkedin-post-analysis"
            >
              <span className="sidebar-nav__icon sidebar-nav__icon--file-image"></span>
              {isOpen && (
                <span className="sidebar-nav__nav-item">
                  Review My LinkedIn Post
                </span>
              )}
            </a>
          </li>
          <li>
            <a className="sidebar-nav__list-link" href="/trends">
              <span className=" sidebar-nav__icon sidebar-nav__icon--graph-up"></span>
              {isOpen && (
                <span className="sidebar-nav__nav-item">
                  Discover Trending Topics
                </span>
              )}
            </a>
          </li>
          <li>
            <a className="sidebar-nav__list-link" href="/industries-and-trends">
              <span className=" sidebar-nav__icon sidebar-nav__icon--graph-up"></span>
              {isOpen && (
                <span className="sidebar-nav__nav-item">
                  Discover Trending Topics - v2
                </span>
              )}
            </a>
          </li>
        </ul>
      </nav>

      <LanguageSwitcher />
      <Logout />
    </div>
  );
};
