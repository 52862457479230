import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LinkedinPostAnalysis from "./components/LinkedinPostAnalysis";
import LinkedInRedirect from "./components/LinkedInRedirect";
import LinkedInAuth from "./components/LinkedInAuth";
import LinkedInPost from "./components/LinkedInPost";
import logo from "./logo.png";
import PostAnalysis from "./components/pages/PostAnalysis";
import Trends from "./components/pages/Trends";
import Home from "./components/pages/Home";
import IndustriesAndTrends from "./components/pages/IndustriesAndTrends";

function App() {
  return (
    <div className="container">
      <div className="home">
        <div className="wrapper">
          <div className="logo-column">
            <a href="/">
              <img src={logo} alt="Logo" className="logo" />
            </a>
          </div>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/linkedin-post-analysis"
                element={<LinkedinPostAnalysis />}
              />
              <Route path="/post-analysis" element={<PostAnalysis />} />
              <Route path="/trends" element={<Trends />} />
              <Route
                path="/industries-and-trends"
                element={<IndustriesAndTrends />}
              />
              <Route path="/linkedin-auth" element={<LinkedInRedirect />} />
              <Route path="/linkedin-callback" element={<LinkedInAuth />} />
              <Route path="/linkedin-post" element={<LinkedInPost />} />
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
