import React, { useEffect } from "react";
import "../../style/Home.scss";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Logout } from "pages";
import { loginRequest } from "authConfig";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const HomeWrapper = (props: any) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.error("Login failed: ", error);
    });
  };

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);

    if (!isAuthenticated) {
      handleLogin();
    }
  }, [handleLogin]);

  return (
    <>
      {/* <div>
        {isAuthenticated
          ? "You're signed in!"
          : "You're not signed in. Please click the button to sign-in or sign-up"}
      </div> */}
      <div>{isAuthenticated && <Logout />}</div>
      <div>{props.children}</div>
    </>
  );
};
