import axios from "axios";

// Utility function for making Trends API call
export const getTrends = async (trends, uri) => {
  // Prepare the request data
  const data = JSON.stringify({
    input_content: trends,
  });

  // Set up the request configuration
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: uri, // Pass the URI as a parameter
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    // Make the API request
    const response = await axios.request(config);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching trends:", error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};
