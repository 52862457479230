import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";

export const SignupSignin = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.error("Login failed: ", error);
    });
  };

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  return <button onClick={handleLogin}>Login</button>;
};
